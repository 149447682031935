import React, { useState } from 'react';
import { Control } from 'src/api/types';
import { DataTitle, DataValue, NoData, PageCard, Poppins, Settings, Spacer, TitleWithBack } from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import CommonMenu from 'src/components/CommonMenu';
import { formatDate, formatNumber } from 'src/utils/misc';
import { Link } from 'react-router-dom';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import useVersion from 'src/hooks/useVersion';
import { formatUrl } from 'src/utils/misc';
import { OpenInNew } from '@mui/icons-material';
import { TLLink } from 'src/pages/DashboardPage/comps';

const WhiteBox = styled(PageCard)`
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-weight: 600;
      line-height: 48px;
      color: ${colors.prussianBlue};
    }
  }

  .grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
    max-width: 1100px;

    &--1-3 {
      grid-template-columns: 1fr 3fr;
      padding-right: 100px;
    }
  }
  .panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid-view {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      &--1-3 {
        grid-template-columns: 1fr;
        padding-right: 100px;
      }
    }
  }
`;

interface MetaSheetProps {
  data: Control;
  editable?: { onEditSelect: () => void; onDeleteSelect: () => void };
  children?: React.ReactNode;
}

export const MetaSheet: React.FC<MetaSheetProps> = ({ data, editable, children }) => {
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);
  const { version, VHeader } = useVersion();

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <WhiteBox>
        <div className="head">
          <TitleWithBack title="CONTROL" gradient />
          {editable && !version && (
            <>
              <Settings
                active={!!anchorEl}
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
                data-cy="common-menu"
              />
              <CommonMenu
                onEditClick={(e) => onMenuClick(e, () => editable.onEditSelect())}
                onDeleteClick={(e) => onMenuClick(e, () => editable.onDeleteSelect())}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              />
            </>
          )}
        </div>
        <Spacer $px={30} />
        <Poppins className="head__title" px={32} data-cy="title">
          {data.name}
        </Poppins>
        {VHeader && (
          <>
            <Spacer $px={5} />
            {VHeader}
          </>
        )}
        <Spacer $px={30} />
        <div className="grid-view">
          <div>
            <DataTitle>STRATEGY</DataTitle>
            {data.strategy ? <DataValue>{data.strategy}</DataValue> : <NoData />}
          </div>
          <div>
            <DataTitle>STATUS</DataTitle>
            {data.status ? <DataValue>{data.status}</DataValue> : <NoData />}
          </div>
          <div>
            <DataTitle>LINK</DataTitle>
            {data.source ? (
              <DataValue>
                <a
                  href={formatUrl(data.source)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="table-link"
                  onClick={() =>
                    mpEvent(MPEvents.Link, {
                      source: { value: ['Control'], params: { id: data.id } },
                      destination: { value: ['External URL'] },
                      tags: ['CONTROL'],
                    })
                  }
                >
                  {data.url_name || data.source}{' '}
                  <OpenInNew
                    css={`
                      font-size: 14px !important;
                      color: ${colors.prussianBlue};
                    `}
                  />
                </a>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <Spacer $px={40} />
        <div className="grid-view">
          <div>
            <DataTitle>OWNER</DataTitle>
            {data.owner?.text ? <DataValue>{data.owner.text}</DataValue> : <NoData />}
          </div>
          <div>
            <DataTitle>ANNUAL COST</DataTitle>
            {data.annual_cost ? <DataValue>£{formatNumber(data.annual_cost)}</DataValue> : <NoData />}
          </div>
          <div>
            <DataTitle>VENDOR</DataTitle>
            {data.vendor ? <DataValue>{data.vendor}</DataValue> : <NoData />}
          </div>
        </div>
        {data.frameworkLibrary_name && data.controlId && (
          <>
            <Spacer $px={40} />
            <div className="grid-view grid-view--1-3">
              <div>
                <DataTitle>FRAMEWORK ID</DataTitle>
                <DataValue>{data.controlId}</DataValue>
              </div>
              <div>
                <DataTitle>FRAMEWORK</DataTitle>
                <DataValue className="table-link">
                  <Link
                    to={`/control-frameworks/${data.frameworkLibrary_ux_id}`}
                    data-cy="framework-link"
                    onClick={() =>
                      mpEvent(MPEvents.Link, {
                        source: {
                          value: ['Control'],
                        },
                        destination: { value: ['Framework'], params: { id: data.frameworkLibrary_ux_id } },
                        tags: ['RMP'],
                      })
                    }
                  >
                    {data.frameworkLibrary_name}
                  </Link>
                </DataValue>
              </div>
            </div>
          </>
        )}
        <Spacer $px={40} />
        <div className="grid-view">
          <div>
            <DataTitle>EXPECTED LIVE DATE</DataTitle>
            {data.expected_active_date ? (
              <DataValue>
                <TLLink date={data.expected_active_date}>{formatDate(data.expected_active_date, true)}</TLLink>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div>
            <DataTitle>ACTUAL LIVE DATE</DataTitle>
            {data.actual_active_date ? (
              <DataValue>
                <TLLink date={data.actual_active_date}>{formatDate(data.actual_active_date, true)}</TLLink>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div>
            <DataTitle>REVIEW DATE</DataTitle>
            {data.review_date ? (
              <DataValue>
                <TLLink date={data.review_date}>{formatDate(data.review_date, true)}</TLLink>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
          <div>
            <DataTitle>LAST EDITED</DataTitle>
            {data.updated_at ? (
              <DataValue>
                <TLLink date={data.updated_at}>{formatDate(data.updated_at)}</TLLink>
              </DataValue>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <Spacer $px={40} />
        <div>
          <DataTitle>DESCRIPTION</DataTitle>
          {data.description ? <ExpandCollapse text={data.description} /> : <NoData />}
        </div>
        {children}
        <Spacer $px={30} />
      </WhiteBox>
    </>
  );
};
